<template>
  <div class="d-flex flex-column fill-height">
    <div>
      <v-simple-table height="100%" dense fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Fecha
              </th>
              <th class="text-left">
                Municipio
              </th>
              <th class="text-left">
                Categoría
              </th>
              <th class="text-left">
                <div class="d-flex justify-space-between">
                  <span style="align-self: center">Tipo Incidente</span>
                </div>
              </th>
              <th>
                <v-btn
                  v-if="itemsTable && itemsTable.length"
                  color="success"
                  @click="toExcel"
                  icon
                >
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </th>
            </tr>
          </thead>
            <tbody>
              <tr
                style="cursor: pointer;"
                :class="{ active: index === selectedIndex }"
                v-for="(item, index) in itemsTable"
                :key="item.id"
              >
                <td>
                  <v-chip
                    dark
                    label
                    small
                  >
                    {{ item.attributes.fecha }}
                  </v-chip>
                </td>
                <td>{{ item.attributes.municipio }}</td>
                <td>{{ mapCategory[item.category] || '' }}</td>
                <td>{{ item.tipoIncidente }}</td>
                <td>
                  <v-btn color="secondary" @click="showDetails(item)" icon>
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
        </template>
      </v-simple-table>
    </div>
    <template>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Incidente
          </v-card-title>

          <v-card-text>
            <v-simple-table dense class="desc-table">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(item, key) in showing"
                    :key="key"
                  >
                    <td><strong>{{ item.label }}</strong></td>
                    <td v-html="item.value"></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
  </div>
</template>

<script>
import moment from 'moment'
import _ from 'underscore'
import { mapGetters } from 'vuex'
import Dialog from '@/components/dialog'
import Form from '@/components/form'
import ApiService from '@/util/api.service'
import ExportToExcel from '@/util/json_to_excel.service'
import { periods } from '@/util'

export default {
  name: 'EventsReport',
  props: {
    devices: Array,
    groups: Array,
  },
  data() {
    return {
      itemsTable: [],
      selected: null,
      selectedIndex: null,
      showing: {},
      dialog: false,
      filtro: {
        from: new Date(),
        fromHour: '00:00',
        to: new Date(),
        toHour: '23:59'
      },
      mapCategory: {
        seguridad: 'Seguridad',
        medico: 'Médico',
        proteccion_civil: 'Protección Civil',
        servicios_publicos: 'Servicios Públicos'
      },
      filtersForm: {
        category: {
          label: 'Categoria',
          type: String,
          optional: true,
          options: [
            { id: 'seguridad', name: 'Seguridad' },
            { id: 'medico', name: 'Médico' },
            { id: 'proteccion_civil', name: 'Protección Civil' },
            { id: 'servicios_publicos', name: 'Servicios Públicos' }
          ]
        },
        periodo: {
          label: 'Periodo',
          type: String,
          options: periods,
          onChange: (val, fields) => {
            fields.from_separator.setVisibility(val === 'custom')
            fields.from.setVisibility(val === 'custom')
            fields.fromHour.setVisibility(val === 'custom')
            fields.to_separator.setVisibility(val === 'custom')
            fields.to.setVisibility(val === 'custom')
            fields.toHour.setVisibility(val === 'custom')
          }
        },
        from_separator: {
          label: 'Desde',
          type: String,
          separator: true,
          optional: true
        },
        from: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        fromHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        },
        to_separator: {
          label: 'Hasta',
          type: String,
          separator: true,
          optional: true
        },
        to: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        toHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        }
      },
      fields: [{
        field: 'uniqueId',
        fieldName: 'IDENTIFICADOR'
      }, {
        field: 'groupName',
        fieldName: 'GRUPO',
      }, {
        field: (item) => item?.attributes?.fecha?.replaceAll('/', '-'),
        fieldName: 'FECHA'
      }, {
        field: (item) => moment(item.lastUpdate || item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
        fieldName: 'ULTIMA ACTUALIZACIÓN'
      }, {
        field: (item) => item.attributes?.prioridad,
        fieldName: 'PRIORIDAD'
      }, {
        field: (item) => item.tipoIncidente,
        fieldName: 'MOTIVO'
      }, {
        field: (item) => {
          // console.log(item)
          // return `<a href="https://maps.google.com/maps?q=${item.latitude}%2C${item.longitude}&z=17&hl=es" target="_blank"> ${(item.latitude + '0000000000').substring(0,10)}, ${(item.longitude + '0000000000').substring(0,10)} </a>`
          let str = '<a href="https://maps.google.com/maps?q='
          str += item.latitude
          str += '%2C'
          str += item.longitude
          str += '&z=17&hl=es" target="_blank"> '
          str += (item.latitude + '0000000000').substring(0, 10)
          str += ', '
          str += (item.longitude + '0000000000').substring(0, 10)
          str += '</a>'
          return str
        },
        fieldName: 'UBICACIÓN'
      }, {
        field: (item) => `${item.attributes?.lugar?.trim()}, ${item.attributes?.colonia?.trim()}, ${item.attributes?.localidad?.trim() ? item.attributes?.localidad?.trim() : 'Desconocido'}, ${item.attributes?.municipio?.trim()}`,
        fieldName: 'DIRECCIÓN'
      }, {
        field: (item) => item.attributes?.referencias,
        fieldName: 'REFERENCIAS'
      }, {
        field: (item) => item.attributes?.descripcion,
        fieldName: 'DESCRIPCIÓN'
      }]
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  mounted() {

  },
  methods: {
    async load() {
      window.VMA.loading(true)
      const data = {
        ...this.period
      }
      try {
        this.itemsTable = await ApiService({
          url: '/incidents/reports/range',
          method: 'get',
          params: data
        })
        window.VMA.loading(false)
      } catch (e) {
        console.error(e)
        window.VMA.showError({ title: 'Ocurrió un error al cargar los datos' })
      } finally {
        window.VMA.loading(false)
      }
    },
    filtrar() {
      const form = new Form({
        schema: this.filtersForm,
        item: this.filtro
      })
      const dialog = new Dialog({
        title: 'Filtrar rutas',
        actions: [{
          help: 'Filtrar',
          icon: 'mdi-filter',
          color: 'secondary',
          action: async () => {
            if (form.hasErrors()) {
              return
            }
            const item = form.getItem()
            this.filtro = item
            if (item.periodo !== 'custom') {
              this.period = form.fields.periodo.getOption().getPeriod()
            } else {
              this.period = form.fields.periodo.getOption().getPeriod({
                ..._.pick(item, 'from', 'to', 'fromHour', 'toHour')
              })
            }
            if (this.monthDiff(this.period.from, this.period.to) > 0) {
              window.VMA.showMessage({ color: 'error', body: 'Máximo un mes de rango' })
              return
            }
            this.load()
            dialog.close()
          }
        }]
      })
      dialog.open()
      dialog.append(form)
    },
    toExcel() {
      ExportToExcel(
        'Resumen de unidades '
          + moment(this.period.from).format('DD-MM-YYYY HH mm') + ' a '
          + moment(this.period.to).format('DD-MM-YYYY HH mm'),
        this.itemsTable.map((item) => ({
          Unidad: item.deviceName,
          'Velocidad Promedio': item.averageSpeed,
          Distancia: item.distance,
          'Odometro Inicial': item.startOdometer,
          'Odometro Final': item.endOdometer,
          'Velocidad Máxima': item.maxSpeed,
          'Gasolina Gastada': item.spentFuel,
          'Horas de Motor': item.engineHours
        }))
      )
    },
    showDetails(item) {
      this.showing = this.fields.map((i) => ({
        label: i.fieldName,
        value: _.isFunction(i.field) ? i.field(item) : item[i.field]
      }))
      this.dialog = true
    },
    monthDiff(d1, d2) {
      let months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    }
  }
}

</script>

<style lang="scss">
  .map {
    height: 100%;
    width: 100%;
  }
</style>
